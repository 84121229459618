var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rc-view__docs"},[_c('v-container',{staticClass:"rc-padding fluid"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","pa-3":"","text-xs-center":""}},[_c('h1',{staticClass:"rc-tituloSection"},[_vm._v("Documentos")])])],1)],1),_c('div',{staticClass:"rc__view img__bg"},[_c('v-container',{staticClass:"mt-5"},[_c('v-layout',{attrs:{"justify-center":"","row":"","wrap":"","align-center":""}},[_c('img',{staticClass:"img__folder",attrs:{"src":"/images/docs-descargables/carpeta.png","contain":""}}),_c('div',{staticClass:"container__card"},[_vm._l((_vm.docsDescargables),function(doc){return [_c('v-hover',{key:doc.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{key:doc.id,staticClass:"pa-4 my-3",class:{ 'on-hover': hover },attrs:{"max-width":"600px","flat":""}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","gap":"1.5em","align-items":"center"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","gap":"1.5em"}},[_c('img',{attrs:{"src":"/images/docs-descargables/pdf_icon.svg","height":"35px","width":"35px","contain":""}}),_c('div',[_c('div',{staticClass:"doc__title"},[_vm._v(_vm._s(doc.title))]),_c('div',{staticClass:"doc__update"},[_vm._v(_vm._s(doc.update))])])]),_c('v-spacer'),_c('div',[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","gap":"0.5em"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{key:doc.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g({class:{ 'on-hover': hover },staticStyle:{"padding":"10px"},attrs:{"color":"#FF8A00"},on:{"click":function($event){return _vm.previewFile(doc.id)}}},on),[_vm._v("visibility")])]}}],null,true)})]}}],null,true)},[_vm._v("\n                                                Ver documento\n                                            ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{key:doc.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',_vm._g({class:{ 'on-hover': hover },attrs:{"href":doc.src,"download":""}},on),[_c('v-icon',{staticStyle:{"padding":"10px"},attrs:{"color":"#FF8A00"}},[_vm._v("download")])],1)]}}],null,true)})]}}],null,true)},[_vm._v("\n                                                Descargar\n                                            ")])],1)])],1)])]}}],null,true)})]})],2)])],1)],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","width":"70vw"},model:{value:(_vm.modalPreview),callback:function ($$v) {_vm.modalPreview=$$v},expression:"modalPreview"}},[_c('v-card',{staticStyle:{"background":"rgb(50, 54, 57)"}},[_c('v-card-title',{staticStyle:{"height":"50px","padding":"0","display":"flex","justify-content":"end"}},[_c('div',{staticClass:"container__header_btn"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.modalPreview = false}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("close")])],1)],1)]),_c('v-divider'),_c('v-card-text',{staticStyle:{"max-width":"70vw","height":"80vh"}},[_c('vue-pdf-embed',{attrs:{"source":_vm.srcPreview}})],1),_c('v-divider'),_c('v-card-actions',{staticStyle:{"height":"40px"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }