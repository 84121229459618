<template>
    <div class="rc-view__docs">
        <v-container class="rc-padding fluid">
            <v-layout row wrap>
                <v-flex xs12 pa-3 text-xs-center>
                    <h1 class="rc-tituloSection">Documentos</h1>
                </v-flex>
            </v-layout>
        </v-container>
        <!-- documentos -->
        <div class="rc__view img__bg">
            <v-container class="mt-5">
                <v-layout justify-center row wrap align-center>
                    <img src="/images/docs-descargables/carpeta.png" class="img__folder" contain />
                    <div class="container__card">
                        <template v-for="doc in docsDescargables">
                            <v-hover v-slot="{ hover }" :key="doc.id">
                                <v-card max-width="600px" class="pa-4 my-3" flat :key="doc.id"  :class="{ 'on-hover': hover }">
                                    <div style="display: flex; flex-direction: row; gap: 1.5em; align-items: center;">
                                        <div style="display: flex; flex-direction: row; gap: 1.5em">
                                            <!-- img -->
                                            <img src="/images/docs-descargables/pdf_icon.svg" height='35px' width="35px" contain/>
                                            <!-- titulo y fecha de actualizacion -->
                                            <div>
                                                <div class="doc__title">{{ doc.title}}</div>
                                                <div class="doc__update">{{ doc.update }}</div>
                                            </div>
                                        </div>
                                        <v-spacer></v-spacer>
                                        <div>
                                            <div style="display: flex; flex-direction: row; gap: 0.5em">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-hover v-slot="{ hover }" :key="doc.id">
                                                            <v-icon :class="{ 'on-hover': hover }" v-on="on" style="padding: 10px;" color="#FF8A00" @click="previewFile(doc.id)">visibility</v-icon>
                                                        </v-hover>
                                                    </template>
                                                    Ver documento
                                                </v-tooltip>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-hover v-slot="{ hover }" :key="doc.id">
                                                            <a :class="{ 'on-hover': hover }" v-on="on" :href="doc.src" download><v-icon style="padding: 10px;" color="#FF8A00">download</v-icon></a>
                                                        </v-hover>
                                                    </template>
                                                    Descargar
                                                </v-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </v-card>
                            </v-hover>
                        </template>
                    </div>
                </v-layout>
            </v-container>
        </div>
        <v-dialog v-model="modalPreview" scrollable  persistent width="70vw">
            <v-card style="background:rgb(50, 54, 57);">
                <v-card-title style="height: 50px;padding: 0;display: flex;justify-content: end;">
                    <div class="container__header_btn">
                        <v-btn icon @click="modalPreview = false"><v-icon color="white">close</v-icon></v-btn>
                    </div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="max-width: 70vw; height: 80vh;">
                    <vue-pdf-embed :source="srcPreview" />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions style="height: 40px;" />
            </v-card>
        </v-dialog>
    </div>
    
</template>
<script>

import { Component, Vue } from "vue-property-decorator";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import {
  RedCapital,
  SelectedCountry,
} from "@/configuration";


@Component({
    metaInfo: {
        title: "RedCapital  | Plataforma de Financiamiento Digital ",
        //titleTemplate: TitleTemplatePublic,
        htmlAttrs: {
        // @ts-ignore
        lang: RedCapital[SelectedCountry].Lang
        },
    },
    data(){
        return {
            docsDescargables:[
                {
                    id: 1,
                    title: 'Términos y condiciones',
                    update: 'Última actualización: 29-08-2022',
                    src: '/pdf/terminos-y-condiciones.pdf'
                },
            ],
            srcPreview : '',
            modalPreview: false,
        }
    },
    methods:{
        previewFile(id){
            const documento = this.$data.docsDescargables.find(doc => doc.id == id);
            this.$data.srcPreview = documento.src;
            this.$data.modalPreview = true;
        }
    },
    components:{
        VuePdfEmbed
    }

})
export default class DocumentosDescargables extends Vue {}
</script>

<style lang="scss">

.img__bg{
    background: url( '/images/docs-descargables/bg.png') no-repeat left bottom;
    background-size: contain;
}

.img__folder{
    display: flex;
    height: 350px;
}
.doc__title{
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #3D3D3D;

}
.doc__update{
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #8B8B8B;

}
.rc-view__docs{
	font-family: $rc-font-sf;
}

.container__header_btn{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.container__card{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
}
.v-card.on-hover{
    background-color: rgba(#FFF, 0.8);
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
}

.v-icon.on-hover{
    background-color: rgba(#FFF3E2, 0.8);
    border-radius: 100%;
    padding: 10px;
}
a.on-hover{
    background-color: rgba(#FFF3E2, 0.8);
    border-radius: 50%;
}

@each $breakpoint in $breakpoints_screen_text{
    @include breakpoint_media_queries($breakpoint){
        @if $breakpoint == "x-large"{
            .rc__view{
                height: calc(100vh - 56px);
                min-height: calc(768px - 56px);
				max-height: calc(768px - 56px);
            }
            .img__bg{
                background-size: 800px;
            }
        }@else if $breakpoint == "large"{
            .img__bg{
                background-size: 600px;
            }
            .rc__view{
                height: calc(100vh - 56px);
                min-height: calc(600px - 56px);
				max-height: calc(600px - 56px);
            }
        }@else if $breakpoint == "medium-to-large"{
            .img__bg{
                background-size: 600px;
            }
            .img__folder{
                display: flex;
                height: 300px;
            }
            .rc__view{
                height: calc(100vh - 56px);
                min-height: calc(600px - 56px);
				max-height: calc(600px - 56px);
            }
        }@else if $breakpoint == "small-to-medium"{
            .img__bg{
                background-size: 300px;
            }
            .img__folder{
                display: none;
            }
            .rc__view{
                height: calc(100vh - 56px);
                min-height: calc(400px - 56px);
                max-height: calc(400px - 56px);
            }
        }@else if $breakpoint == "small"{
            .img__bg{
                background-size: 150px;
            }
            .img__folder{
                display: none;
            }
            .doc__title{
                font-size: 14px;
            }
            .doc__update{
                font-size: 12px;
            }
            .rc__view{
                height: calc(100vh - 56px);
                min-height: calc(600px - 56px);
                max-height: calc(600px - 56px);
            }
        }
    }
}

</style>